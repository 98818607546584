import React, { Component } from 'react';
import { Link, NavLink } from 'react-router-dom';

import { UserIcon } from '@heroicons/react/24/outline';
import { Routes, Route, Navigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { observable, makeObservable } from 'mobx';
import withRouter from '../utils/withRouter';
import CheckIcon from '../media/CheckIcon';
import LockClosedIcon from '../media/LockIcon';

import { observer, inject } from 'mobx-react';
import Logo from '../Components/Logo';
@inject('store')
@observer
class Login extends Component {
  @observable email = '';
  @observable password = '';
  @observable fname = '';
  @observable lname = '';
  @observable errorMessage = '';
  @observable isVerificationSent = false;

  constructor() {
    super();
    makeObservable(this);
  }

  onChange = (val) => {
    this.currentPromptOption = val;
    console.log(this.currentPromptOption);
  };

  onChangeAny = (val, attr) => {
    this[attr] = val;
    this.errorMessage = '';
  };

  onLogin = async (e) => {
    try {
      e.preventDefault();
      let data = await this.props.store.api
        .post('/auth/signin', {
          email: this.email,
          password: this.password,
        })
        .then(({ data }) => data);
      this.props.store.loginWithDataTokenAndProfile(data);
      this.props.navigate('/');
    } catch (err) {
      console.log(err);
      console.log(err?.response?.data?.message);
      if (err?.response?.data?.message) {
        this.errorMessage = err?.response?.data?.message;
      }
    }
  };

  onSignup = async (e) => {
    try {
      e.preventDefault();
      this.errorMessage = '';

      await this.props.store.api.post('/auth/signup', {
        email: this.email,
        password: this.password,
        fname: this.fname,
        lname: this.lname,
        referral: this.props.store.referral,
      });

      this.isVerificationSent = true;
    } catch (err) {
      console.log(err);
      console.log(err?.response?.data?.message);
      if (err?.response?.data?.message) {
        this.errorMessage = err?.response?.data?.message;
      }
    }
  };

  // Currently Selected Input Option

  render() {
    if (this.isVerificationSent) {
      return (
        <div
          className={
            'container mx-auto lg:px-4 py-4 min-h-screen ' +
            'flex flex-col md:items-center md:justify-center'
          }
        >
          <div className="text-center mb-8">
            <Logo style={{ margin: '0 auto ' }} />
          </div>

          <div
            className={
              'min-w-full md:min-w-0 bg-gray-800 rounded-xl shadow-xl transform ' +
              'transition shadow-md hover:shadow-2xl focus:shadow-2xl w-1/2'
            }
          >
            <div className="px-4 py-4 md:px-12 md:py-12">
              <div>
                <div
                  className={
                    'mx-auto flex-shrink-0 flex items-center justify-center ' +
                    'h-12 w-12 rounded-md bg-gradient-to-r from-blue-400 to-violet-400'
                  }
                >
                  <UserIcon
                    className={`h-8 w-8 text-gray-800`}
                    aria-hidden="true"
                  />
                </div>

                <div className="text-3xl text-center mt-3 mb-1 font-medium text-purple-400 ">
                  Verify Your Email
                </div>

                <div className="text-blue-500 bg-gray-700 rounded-md p-2 text-center mt-4">
                  <p>
                    The verification link has been sent successfully to your
                    email
                  </p>

                  <p>"{this.email}"</p>

                  <p>
                    please check your inbox / junk or promotional tab
                    ...etc
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }

    return (
      <>
        <Helmet>
          <title>{`LOGIN-CODE-E`}</title>
        </Helmet>
        <div className="container mx-auto lg:px-4 py-4 min-h-screen flex flex-col md:items-center md:justify-center">
          <div className="text-center mb-8">
            <Logo style={{ margin: '0 auto' }} />
          </div>
          <div
            className={`min-w-full md:min-w-0 bg-gray-800 rounded-xl transform  transition shadow-xl hover:shadow-2xl focus:shadow-2xl w-1/2`}
          >
            <div className="align-bottom transform sm:align-middle transition flex divide-x divide-gray-300 ">
              <NavLink
                to="/login"
                className={`flex-1 justify-center transition py-4 px-4 pr-8 rounded-t-md flex ${
                  this.props.location.pathname === '/login'
                    ? 'text-blue-500'
                    : 'text-blue-500'
                } font-medium  ${
                  this.props.location.pathname === '/login'
                    ? 'bg-gray-800'
                    : 'bg-gray-700'
                } ${
                  this.props.location.pathname === '/login'
                    ? 'hover:bg-gray-800'
                    : 'hover:bg-gray-800'
                } cursor-pointer`}
              >
                <div
                  className={`transition mr-4  flex-shrink-0 inline-flex items-center justify-center text-sm h-6 w-6 rounded-md ${
                    this.props.location.pathname === '/login'
                      ? 'bg-purple-400'
                      : 'bg-purple-400'
                  }`}
                >
                  <CheckIcon
                    className={`transition h-4 w-4 ${
                      this.props.location.pathname === '/login'
                        ? 'text-blue-500'
                        : 'text-blue-400'
                    }`}
                    aria-hidden="true"
                  />
                </div>
                Login
              </NavLink>
              <NavLink
                to="/signup"
                className={`flex-1 justify-center transition py-4 px-4 pr-8 rounded-t-md flex ${
                  this.props.location.pathname === '/signup'
                    ? 'text-blue-500'
                    : 'text-blue-500'
                } font-medium  ${
                  this.props.location.pathname === '/signup'
                    ? 'bg-gray-800'
                    : 'bg-gray-700'
                } ${
                  this.props.location.pathname === '/signup'
                    ? 'hover:bg-gray-800'
                    : 'hover:bg-gray-800'
                } cursor-pointer`}
              >
                <div
                  className={`transition mr-4  flex-shrink-0 inline-flex items-center justify-center text-sm h-6 w-6 rounded-md ${
                    this.props.location.pathname === '/signup'
                      ? 'bg-purple-400'
                      : 'bg-purple-400'
                  }`}
                >
                  <CheckIcon
                    className={`transition h-4 w-4 ${
                      this.props.location.pathname === '/signup'
                        ? 'text-blue-500'
                        : 'text-blue-500'
                    }`}
                    aria-hidden="true"
                  />
                </div>
                Signup
              </NavLink>
            </div>
            <div className="px-4 py-4 md:px-12 md:py-12">
              {/* Sorru */}
              <Routes>
                <Route
                  path="/login"
                  element={
                    <Logon
                      landingPageUrl={this.props.store.landingPageUrl}
                      email={this.email}
                      password={this.password}
                      signUp={this.signUpWithGoogle}
                      onChange={this.onChangeAny}
                      onLogin={this.onLogin}
                    />
                  }
                />

                <Route
                  path="/signup"
                  element={
                    <Signup
                      email={this.email}
                      password={this.password}
                      fname={this.fname}
                      lname={this.lname}
                      onChange={this.onChangeAny}
                      onSignup={this.onSignup}
                    />
                  }
                />

                <Route path="/*" element={<Navigate to="/login" />} />
              </Routes>
              {this.errorMessage ? (
                <div className="text-red-600 bg-gray-700 rounded-md p-1 text-center mt-4">
                  {this.errorMessage}
                </div>
              ) : null}
            </div>
            <Link
              to="/"
              className="block text-center bg-gray-700 text-blue-500 text-sm p-3 rounded-b-lg hover:bg-gray-600 cursor-pointer"
            >
              Back to the future.
            </Link>
          </div>
        </div>
      </>
    );
  }
}

const Logon = observer(
  ({ active, email, password, onChange, onLogin, landingPageUrl, signUp }) => {
    return (
      <>
        <form onSubmit={onLogin}>
          <div
            className={`mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-xl bg-gradient-to-r from-blue-400 to-purple-400 ${
              email && password ? 'bg-purple-400' : 'bg-gray-700'
            } `}
          >
            <LockClosedIcon
              className={`h-8 w-8  ${
                email && password ? 'text-gray-800' : 'text-gray-800'
              }`}
              aria-hidden="true"
            />
          </div>
          <div className="mt-3 text-center ">
            <div className="text-3xl font-medium text-purple-400">Log in</div>
            <p className="text-lg text-blue-500">Login to your account</p>
            <div className="flex flex-col flex-1">
              <label className="text-blue-500 text-sm block mt-4 text-left">
                Email Address
              </label>
              <input
                value={email}
                onChange={(e) => onChange(e.target.value, 'email')}
                focus="true"
                type="email"
                className="rounded-md text-lg px-4 py-2  border border-blue-500 "
                placeholder="emample@email.com"
              />
            </div>
            <div className="flex flex-col flex-1">
              <label className="text-blue-500 text-sm block mt-4 text-left">
                Password
              </label>
              <input
                value={password}
                onChange={(e) => onChange(e.target.value, 'password')}
                type="password"
                className="rounded-md text-lg px-4 py-2  border border-blue-500 inline-block"
                placeholder="*******"
              />
            </div>
            <div className="flex flex-col">
              <button
                type="submit"
                className="hover:bg-gray-600 font-medium rounded-lg text-lg px-4 py-2 bg-gray-700 text-purple-400 mt-4 border border-blue-500 inline-block"
              >
                Log in
              </button>
              {/* <div onClick={signUp} className="hover:bg-gray-600 font-medium rounded-lg text-lg px-4 py-2 bg-gray-500 text-white mt-4 border border-gray-300 inline-block" >
						signUp Google
						</div>
						 */}
              <Link
                to="/forgot-password"
                className="mt-4 text-blue-500 text-sm"
              >
                Forgot your password?
              </Link>
            </div>
          </div>
        </form>
      </>
    );
  },
);

const Signup = observer(
  ({ active, email, password, fname, lname, onChange, onSignup }) => {
    return (
      <>
        {/* onSignup */}
        <form onSubmit={onSignup}>
          <div
            className={`mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-xl bg-gradient-to-r from-blue-400 to-purple-400 ${
              email && password ? 'bg-purple-400' : 'bg-purple-400'
            }`}
          >
            <UserIcon
              className={`h-8 w-8 ${
                email && password ? 'text-gray-800' : 'text-gray-800'
              }`}
              aria-hidden="true"
            />
          </div>
          <div className="mt-3 text-center ">
            <div className="text-3xl font-medium text-purple-400">Sign Up</div>
            <p className="text-lg text-blue-500">Create your account</p>
            <div className="md:flex">
              <div className="flex flex-col min-w-0 md:pr-2 flex-1">
                <label className="text-blue-500 text-sm block mt-4 text-left">
                  First Name
                </label>
                <input
                  value={fname}
                  onChange={(e) => onChange(e.target.value, 'fname')}
                  type="text"
                  className="rounded-md text-lg px-4 py-2 border border-blue-500 w-auto"
                  placeholder="John"
                />
              </div>
              <div className="flex flex-col min-w-0 md:pl-2 flex-1">
                <label className="text-blue-500 text-sm block mt-4 text-left">
                  Last Name
                </label>
                <input
                  value={lname}
                  onChange={(e) => onChange(e.target.value, 'lname')}
                  type="text"
                  className="rounded-md text-lg px-4 py-2  border border-blue-500 inline-block w-auto"
                  placeholder="Smith"
                />
              </div>
            </div>
            <div className="flex flex-col">
              <label className="text-blue-500 text-sm block mt-4 text-left">
                Email Address
              </label>
              <input
                value={email}
                onChange={(e) => onChange(e.target.value, 'email')}
                focus="true"
                type="email"
                className="rounded-md text-lg px-4 py-2  border border-blue-500"
                placeholder="example@email.com"
              />
            </div>
            <div className="flex flex-col">
              <label className="text-blue-500 text-sm block mt-4 text-left">
                Password
              </label>
              <input
                value={password}
                onChange={(e) => onChange(e.target.value, 'password')}
                type="password"
                className="rounded-md text-lg px-4 py-2  border border-blue-500 inline-block"
                placeholder="*******"
              />
            </div>

            <div className="flex flex-col">
              <button
                type="submit"
                className="hover:bg-gray-600 bg-gray-700 font-medium rounded-lg text-lg px-4 py-2 text-purple-400 mt-4 border border-blue-500 inline-block"
              >
                Sign Up
              </button>
            </div>
          </div>
        </form>
      </>
    );
  },
);

export default withRouter(Login);
