const Logo = ({ className, style }) => {
  style = {
    height: '80px',
    ...style,
  }
  return (
    <img src="/logo-new.png" alt="code-e Logo" style={style} />
  )
};

export default Logo;
