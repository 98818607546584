const config = {
  baseURL:
    process.env.NODE_ENV === 'production'
      ? '/api/'
      : 'http://localhost:3080/api/',
  landingPageUrl: 'https://code-e.app',
  stripe: {
    free: process.env.REACT_APP_STRIPE_PRODUCT_FREE,
    entry: process.env.REACT_APP_STRIPE_PRODUCT_ENTRY,
    pro: process.env.REACT_APP_STRIPE_PRODUCT_PRO,
  },
};

export default config;
